import authService from '@/services/auth'
import {subscribeWP, unsubscribeWP} from '@/web_push'
import {ApnPush} from '@/apn_push'
import {checkNull} from '@/helpers'

const is_dev = process.env.NODE_ENV !== 'production'
const allow_notif = 'Notification' in window;
const allow_web_push = 'PushManager' in window;
const vapidPublicKey = window.APP_CONFIG.VAPID_PUBLIC_KEY
const apnBundleId = window.APP_CONFIG.APN_BUNDLE_ID || null
const apnPushUrl = window.APP_CONFIG.APN_PUSH_URL || null
const allow_apn_push = apnBundleId && 'safari' in window && 'pushNotification' in window.safari

const profile = () => {
	return {
		id: '',
		name: '',
		nick_name: '',
		sex: '',
		seeking: '',
		marital_status_id: '',
		body_type_id: '',
		hair_color_id: '',
		eye_color_id: '',
		height: '',
		weight: '',
		smoke_id: '',
		ethnicity_id: '',
		email: '',
		dob: '',
		description: '',
		country_id: '',
		province_id: '',
		city_id: '',
		email_notifications: '',
		profile_image_thumb: '',
		profile_image_id: '',
		profile_image: '',
		profile_image_blur: '',
		subscription_type: '',
		unread_messages: '',
		unread_notifications: '',
		total_favorites: '',
		total_favorites_others: '',
		total_visits: '',
		onboard: '',
		active: '',
		can_cancel: '',
        boost_premium: '',
		welcome_message: '',
		claimed_free_stickers: '',
		remove_blur: '',
		show_private_album: '',
		inactive: '',
		seconds_spent_on_website: '',
        can_claim_daily_icebreakers: true,
        delay_boost_premium_end: -1,
        delay_complete_register_suggestion: -1,
        delay_boost_profile_suggestion: -1,
        subscription_allowed: false
	}
}

export default {
	namespaced: true,
	state: {
		just_logged_in: false,
		logged_in_time: null,
		authenticated: false,
		token: null,
		trial_key: null,
		credits: 0,
		premium: false,
		premium_days_remaining: null,
		subscription_type: false,
        subscription_allowed: false,
		unread_messages: 0,
		unread_notifications: 0,
		profile: null,
		web_push_initialized: false,
		open_notif_pop_up: false,
		notif_pop_up_closed: false,
	},
	getters: {
		justLoggedIn(state) {
			return state.just_logged_in
		},
		loggedInTime(state) {
			return state.logged_in_time
		},
		isAuthenticated(state) {
			return state.authenticated
		},
		isOnTrial(state) {
			return state.profile && !state.profile.active
		},
		trialEnded(state, getters, rootState, rootGetters) {
			return getters.isOnTrial && (!rootGetters['trial/getTrialStarted'] || rootGetters['trial/getTrialEnded'])
		},
		trialKey(state) {
			return state.trial_key
		},
		token(state) {
			return state.token
		},
		credits(state) {
			return state.credits
		},
		subscriptionType(state) {
			return state.subscription_type
		},
        subscriptionAllowed(state) {
            return state.subscription_allowed
        },
		premium(state) {
			return state.premium
		},
		premiumDaysRemaining(state) {
			return state.premium_days_remaining
		},
		unreadMessages(state) {
			return state.unread_messages
		},
		unreadNotifications(state) {
			return state.unread_notifications
		},
		profile(state) {
			if(state.profile) {
				return state.profile
			}
			return profile()
		},
		webPushInitialized(state) {
			return state.web_push_initialized
		},
		openNotifPopUp(state) {
			return state.open_notif_pop_up
		},
	},
	mutations: {
		setJustLoggedIn(state, payload) {
			state.just_logged_in = payload
		},
		setLoggedInTime(state,payload) {
			state.logged_in_time = payload
		},
		setAuth(state, payload) {
			state.just_logged_in = true
			localStorage.removeItem('base_search')
			state.authenticated = true
			state.token = payload.data.token
			Vue.http.headers.common['Authorization'] = 'Bearer '+ payload.data.token
			if(!is_dev) window.Echo.options.auth.headers['Authorization'] = 'Bearer '+ payload.data.token
		},
		setTrialKey(state, payload) {
			state.trial_key = payload
		},
		removeAuth(state) {
			Vue.http.headers.common['Authorization'] = ''
			state.authenticated = false
			state.token = null
			state.subscription_type = false
			state.unread_messages = 0
			state.unread_notifications = 0
			state.profile = profile()
			state.notif_pop_up_closed = false
			state.open_notif_pop_up = false
		},
		setCredits(state, credits) {
			state.credits = credits
		},
		setSubscriptionType(state, type) {
			state.subscription_type = type
		},
        setSubscriptionAllowed(state, value) {
            state.subscription_allowed = value
        },
		setPremium(state, premium) {
			state.premium = premium
		},
		setPremiumDaysRemaining(state, premium_days_remaining) {
			state.premium_days_remaining = premium_days_remaining
		},
		setUnreadMessages(state, unread_messages) {
			state.unread_messages = unread_messages
		},
		setUnreadNotifications(state, unread_notifications) {
			state.unread_notifications = unread_notifications
		},
		setProfile(state, payload) {
			state.profile = payload.data.user
		},
		updateProfile(state, payload) {
			if (state.profile) {
				Object.keys(payload).map(key => {
					state.profile[key] = payload[key];
				})
			}
		},
		setProfileImage(state, payload) {
			state.profile.profile_image_id = payload.profile_image_id
			state.profile.profile_image = payload.profile_image
			state.profile.profile_image_thumb = payload.profile_image_thumb
			state.profile.profile_image_blur = payload.profile_image_blur
		},
		removeWelcomeMessage(state, payload) {
			state.profile.welcome_message = 2
		},
		setRemoveBlur(state, payload) {
			state.profile.remove_blur = payload.remove_blur
		},
		setWebPushInitialized(state, web_push_initialized) {
			state.web_push_initialized = web_push_initialized
		},
		setOpenNotifPopUp(state, open_notif_pop_up) {
			let temp_disabled_pop_up = Cookies.get('disable_notif_pop_up')
			if (open_notif_pop_up && (state.notif_pop_up_closed || temp_disabled_pop_up)) {
				open_notif_pop_up = false;
			}
			if(open_notif_pop_up && allow_apn_push) {
				let permissionData = window.safari.pushNotification.permission(apnBundleId);
				state.open_notif_pop_up = permissionData.permission == 'default'
			} else if(open_notif_pop_up && allow_notif) {
				state.open_notif_pop_up = Notification.permission == 'default'
			} else {
				state.open_notif_pop_up = false
			}
		},
		setNotifPopUpClosed(state, payload) {
			state.notif_pop_up_closed = payload;
		},
	},
	actions: {
		register(context, {params}) {
			return authService.register(params)
		},
        getPreActivatedUser(context, {token}) {
			return authService.getPreActivatedUser(token)
		},
		checkUniqueEmail(context, {email}) {
			return authService.checkUniqueEmail({email})
		},
		validateEmail(context, {email}) {
			return authService.validateEmail({email})
		},
		checkUniqueNickname(context, payload) {
			return authService.checkUniqueNickname(payload)
		},
		getRandomNickName(context, {sex}) {
			return authService.getRandomNickName({sex})
		},
		resendConfirmEmail(context, {token, params}) {
			return authService.resendConfirmEmail(token, params)
		},
		registerFinish(context, {token, params}) {
			return authService.registerFinish(token, params)
		},
		activate(context, {token, payload}) {
			return authService.activate(token, payload)
		},
		login(context, {params, success_cb, error_cb}) {
			authService.login(params).then(response => {
				if(typeof window.Echo.socketId() == 'undefined' && !is_dev) window.Echo.connect()
				context.commit('setAuth', response)
				context.dispatch('getUser')
				if(typeof success_cb == 'function') success_cb(response)
			}, error_cb)
		},
		autoLogin(context, {params, success_cb, error_cb}) {
			authService.autoLogin(params).then(response => {
				if(checkNull(response.data.response) && response.data.response.toLowerCase() == 'error') {
					error_cb(response)
				} else {
					if(typeof window.Echo.socketId() == 'undefined' && !is_dev) window.Echo.connect()
					context.commit('setAuth', response)
					context.dispatch('getUser')
					if(typeof success_cb == 'function') success_cb(response)
				}
			}, error_cb)
		},
		logout(context) {
			return new Promise((resolve, reject) => {
				if(!is_dev) {
					window.Echo.disconnect();
					context.commit('setOpenNotifPopUp', false)
				}
				if(context.getters.webPushInitialized) {
					if(!is_dev && allow_web_push) {
						//unsubscribe webpush
						context.dispatch('updatePushSub', {type: 'unsubscribe'})
					}
					context.dispatch('performLogout', {handler: resolve})
				} else {
					context.dispatch('performLogout', {handler: reject})
				}
			})
		},
		initializeWebPush(context, success_cb) {
			console.log('web push initializing')
			if(!context.getters.webPushInitialized) {
				if(is_dev) {
					console.log('cancel web push connection')
					window.Echo.disconnect()
				} else {
					if(allow_web_push) {
						context.dispatch('updatePushSub', {type: 'subscribe'})
							.then(res => {
								if(typeof success_cb == 'function') success_cb()
								context.commit('setWebPushInitialized', true)
							}).catch(error => console.log(error))
					} else if (allow_apn_push) {
						context.dispatch('checkApnPermission', (permissionData) => {
							context.commit('setWebPushInitialized', true);
							if(permissionData) {
								if(permissionData.permission === 'granted') {
									if(typeof success_cb == 'function') success_cb()
									authService.apnDeviceRegister(permissionData)
								}
								authService.trackPushNotificationAction('apn-' + permissionData.permission)
							}
						})
					} else {
						console.log('Please enable push notifications from browser preferences to receive them')
					}
				}
			}
		},
		removeAuth(context) {
			context.commit('removeAuth')
			context.commit('setTrialKey', null)
			context.dispatch('pub/allowLeave', true)
		},
		performLogout(context, {handler}) {
			authService.logout()
				.then(response => {
					context.dispatch('removeAuth')
					if (checkNull(handler)) handler(response)
				})
				.catch(error => {
					context.dispatch('removeAuth')
				})
		},
		getUser(context, {delay_webpush = false, success_cb = undefined} = {}) {
			authService.getUser().then(response => {
				context.commit('setProfile', response)
				context.commit('setSubscriptionType', response.data.user.subscription_type)
				context.commit('setUnreadMessages', response.data.user.unread_messages)
				context.commit('setUnreadNotifications', response.data.user.unread_notifications || 0)
				context.commit('setWebPushInitialized', is_dev)
				if (!delay_webpush) context.commit('setOpenNotifPopUp', true)
				if (typeof success_cb == 'function') success_cb(response)
			}, response => {
				console.log(response)
			})
		},
		updatePushSub(context, {type}) {
			return new Promise((resolve, reject) => {
				let s_type = ['subscribe', 'unsubscribe'].indexOf(type) != -1 ? type : 'subscribe'
				console.log('check s_type value: ' + s_type);
				let webpushAction = (s_type == 'subscribe') ?
					subscribeWP(vapidPublicKey)
					: unsubscribeWP()

				webpushAction.then(sub => {
					let skey = checkNull(sub.getKey) && checkNull(sub.getKey('p256dh')) ? sub.getKey('p256dh') : null
					let stoken = checkNull(sub.getKey) && checkNull(sub.getKey('auth')) ? sub.getKey('auth') : null
					let contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]
					let pushUpdate = (s_type == 'subscribe') ?
						authService.pushSubscribe({
							endpoint: sub.endpoint,
							publicKey: skey ? btoa(String.fromCharCode.apply(null, new Uint8Array(skey))) : null,
							authToken: stoken ? btoa(String.fromCharCode.apply(null, new Uint8Array(stoken))) : null,
							contentEncoding
						}) :
						authService.pushUnsubscribe({
							endpoint: sub.endpoint
						})

					pushUpdate.then(res => {
						authService.trackPushNotificationAction('wp-' + s_type)
						if(type == 'subscribe') {
							console.log('Successfully subscribed!')
						} else {
							console.log('Successfully unsubscribed!')
						}
						console.log(res)
						resolve(res)
					}, err => {
						console.log(err)
						reject(err)
					})
				}, err => {
					authService.trackPushNotificationAction('reject')
					console.log(err)
					reject(err)
				})
			})
		},
		checkApnPermission(context,cb) {
			return new ApnPush(apnPushUrl, apnBundleId, context.getters.profile.id).checkPermission(cb)
		},
		getMyProfile(context) {
			return authService.getMyProfile()
		},
		saveMyProfile(context, payload) {
			return authService.saveMyProfile(payload)
		},
		forgotPass(context, payload) {
			return authService.forgotPass(payload)
		},
		saveSettings(context, payload) {
			return authService.saveSettings(payload)
		},
		disableNotifications(context) {
			return authService.disableNotifications()
		},
		deleteAccount(context) {
			return authService.deleteAccount()
		},
		validateNewEmail(context, {token}) {
			return authService.validateNewEmail(token)
		},
		removeBlur(context) {
			return authService.removeBlur()
		},
		registerNotif(context, payload) {
			return authService.registerNotif(payload)
		},
		deRegisterNotif(context, payload) {
			return authService.deRegisterNotif(payload)
		},
		notifPopupAction(context, { accept, success_cb }) {
			if(accept) {
				context.dispatch('initializeWebPush', success_cb)
			} else {
				authService.trackPushNotificationAction('popup-reject')
			}
			context.commit('setNotifPopUpClosed', true)
			context.commit('setOpenNotifPopUp', false)
		},
		updateProfile(context, payload) {
			if(payload.unread_messages) {
				context.commit('setUnreadMessages', payload.unread_messages)
			}
			if(payload.unread_notifications) {
				context.commit('setUnreadNotifications', payload.unread_notifications)
			}
			context.commit('updateProfile', payload)
		},
		claimFreeIcebreaker(context, payload) {
			return authService.claimFreeIcebreaker(payload)
		},
		markAsReadUpdates(context, payload) {
			return authService.markAsReadUpdates(payload)
		},
        completeRegister() {
            return authService.completeRegister()
        },
        confirmCompleteRegister(context, data) {
            return authService.confirmCompleteRegister(data)
        },
        denyCompleteRegister() {
            return authService.denyCompleteRegister()
        },
        shownCompleteRegister() {
            return authService.shownCompleteRegister()
        },
        boostProfile() {
            return authService.boostProfile()
        },
        confirmBoostProfile(context, data) {
            return authService.confirmBoostProfile(data)
        },
        denyBoostProfile() {
            return authService.denyBoostProfile()
        },
        shownBoostProfile() {
            return authService.shownBoostProfile()
        },
        claimDailyFreeIcebreakers() {
            return authService.claimDailyFreeIcebreakers()
        },
        confirmDailyFreeIcebreakers(context, data) {
            return authService.confirmDailyFreeIcebreakers(data)
        }
	}
}
